<!--
 * @Descripttion: 
 * @version: 
 * @Author: 王三华
 * @Date: 2023-08-09 10:30:33
-->
<template>
    <div>
        <el-dialog title="选择店铺" :visible.sync="showSpace" width="40%" center :before-close="handleClose">
            <div style="width:100%">
                <el-cascader v-model="dizhi" clearable    @change="setDizhi" :options="areaData" :props="regionParams" :placeholder="$t('MN_F_T_464')"></el-cascader>
                <el-table class="order" v-show="total>0" ref="table" stripe :data="tableData" style="width:100%;margin-top:10px" height="500px" :header-cell-style="{ background: '#EEEEEE' }" :row-class-name="tableRowClassName" @selection-change="handleSelectionChange"
                    @select="handleSelect">
                    <el-table-column type="selection" width="55" />
                    <el-table-column align="center" prop="storeName" label="店铺名称" />
                    <el-table-column align="center" prop="storeCode" label="店铺编号" />
                    <el-table-column align="center" prop="storeAddress" label="店铺地址" />
                </el-table>
            </div>
            <pagination v-show="total>0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="setDizhi" />
            <span slot="footer" class="dialog-footer">
                                <el-button @click="showSpace = false">取 消</el-button>
                                <el-button type="primary" @click="addCar" class="delBtn">确 定</el-button>
                              </span>
        </el-dialog>
    </div>
</template>

<script>
import * as api from '@/api/register.js'
import * as goods from '@/api/goods.js'

export default {
    data() {
        return {
            showSpace: false,
            areaData: [],
            dizhi: [],
            regionParams: {
                label: 'name',
                value: 'id',
                children: 'children',
                expandTrigger: 'hover',
                checkStrictly: true
            },
            tableData: [],
            total: 0,
            queryParams: {
                pageNum: 1,
                pageSize: 50,
            },
            selSpace: [],
            carList:''
        }
    },
    methods: {
        getData(carList) {
            this.carList = carList
            this.getAllArea()
            this.setDizhi()
            this.queryParams.pageNum = 1
            this.tableData = []
            this.dizhi = []
            this.total = 0
            this.showSpace = true
        },
        getAllArea() {
            api.allArea().then((res) => {
                this.areaData = res.data
            })
        },
        setDizhi() {
            api.list_maker(Object.assign({
                licenseProvince: Number(this.dizhi[0]),
                licenseCity: Number(this.dizhi[1]),
                licenseCounty: Number(this.dizhi[2]) || '',
            }, this.queryParams)).then(res => {
                this.total = res.total
                this.tableData = res.rows
            })
        },
        handleSelectionChange(val) {
            this.selSpace = val

        },
        handleSelect(selection, val) {
            //只能选择一行，选择其他，清除上一行
            if (selection.length > 1) {
                let del_row = selection.shift()
                this.$refs.table.toggleRowSelection(del_row, false) //设置这一行取消选中
            }
        },


        handleClose() {
            this.showSpace = false
        },
        addCar() {
            if (this.selSpace.length == 0) {
                this.$message({
                    message: '请选择店铺',
                    type: 'warning',
                })
                return
            }else{
              this.carList.pkMakerSpace = this.selSpace[0].pkId
              goods.addShopping(this.carList).then((res) => {
                if (res.code == 200) {
                    this.$message({
                        message: this.$t('w_0036'),
                        type: 'success',
                    })
                    this.showSpace = false
                    this.$emit('getCar')
                }
            })
            }
            
        },
        tableRowClassName({ row, rowIndex }) {
            if (rowIndex % 2 == 1) {
                return "warning-row";
            } else if (rowIndex % 2 == 0) {
                return "success-row";
            }
            return "";
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-cascader {
    width: 100%;
}

::v-deep .pagination-container {
    padding: 10px;
}

::v-deep .order .el-table__header-wrapper .el-checkbox {
    display: none
}

// 删除按钮
.delBtn {
    background: #C8161D;
    color: #fff;
    border-color: #C8161D;
}

.delBtn:hover {
    background: #C8161D;
    color: #fff;
    border-color: #C8161D;
}
</style>